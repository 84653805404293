import { render, staticRenderFns } from "./inspecao.vue?vue&type=template&id=365ab248&scoped=true"
import script from "./inspecao.vue?vue&type=script&lang=js"
export * from "./inspecao.vue?vue&type=script&lang=js"
import style0 from "./inspecao.vue?vue&type=style&index=0&id=365ab248&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "365ab248",
  null
  
)

export default component.exports